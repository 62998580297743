import React from "react";

const Home = () => {
  return (
    <>
      {/* Banner  */}
      <section className="header">
        <div className="container">
          <div className="banner">
          <div className="inner-bg">
          <div className="row align-items-center">
            <div className="col-md-8 z-1 pe-md-2 pe-4">
              <div className="top-text mb-3"><span>AI Powered DEX Aggregator</span></div>
              <h1>
              Bitcoin Layer <br /> Focussed Liquidity Hub
              </h1>
              <p className="my-4">
              PlutoSwap enables direct connection of AMM pools across <br className="d-lg-block d-none" />
              different providers and discovers all possible paths for  <br className="d-lg-block d-none" />
              swapping any two tokens on multichain.

              </p>
              <div className="d-flex gap-3 mb-4 mb-lg-5">
                <a href="javascript:void(0)" className="primary-btn"> Launch App </a>
                <a href="javascript:void(0)" className="primary-btn-outline"> Docs </a>
              </div>
              <div className="mb-4 mb-lg-5 chain-text fw-300 d-flex gap-3 align-items-center">Integrated Chains <img src="assets/line.svg" alt="" /></div>
              <div className="company-list pb-5 mb-lg-5">
                <img src="assets/ic-1.svg" alt="" />
                <img src="assets/ic-2.svg" alt="" />
                <img src="assets/ic-3.svg" alt="" />
                <img src="assets/ic-4.svg" alt="" />
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
      </section>

      <section className="partner">
        <div className="container">

        <div className="grey-card pt-lg-5">
        <div className="row">
        <div className="col-md-12 mb-4">
              <h2> <span className="">Featured in</span></h2>
            </div>
        </div>

        <div className="row align-items-center pb-4">
        <div className="col-md-2 col-6 mb-md-0 mb-5">
            <div className="partner-img">
              <img src="assets/p5-vm.png"  alt="" />
            </div>
          </div>
          <div className="col-md-2 col-6 mb-md-0 mb-4">
            <div className="partner-img">
              <img src="assets/p1-vm.png"  alt="" />
            </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="partner-img">
              <img src="assets/p3-vm.png"  alt="" />
            </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="partner-img">
              <img src="assets/p2-vm.png"  alt="" />
            </div>
          </div>
        </div>
        </div>

        </div>
        </section>


      <section className="pro pt-0">
        <div className="container">
          <div className="row ">
            <div className="col-md-4 mb-md-0 mb-4">
              <div className="pro-card">
                <div className="pro-img mb-5 pb-4">
                  <img src="assets/f1.svg" alt="" />
                </div>
                <h4 className="mb-3">Best Prices <br /> In DeFi</h4>
                <p>Swap cryptocurrencies with the best rates using PlutoSwap’s core service.</p>
              </div>
            </div>
            <div className="col-md-4 mb-md-0 mb-4">
              <div className="pro-card">
                <div className="pro-img mb-5 pb-4">
                  <img src="assets/f2.svg" alt="" />
                </div>
                <h4 className="mb-3">Multi-hop <br /> Routes</h4>
                <p>Discover all direct and multihop routes for swapping any two tokens.</p>
              </div>
            </div>
            <div className="col-md-4 mb-md-0 mb-4">
              <div className="pro-card">
                <div className="pro-img mb-5 pb-4">
                  <img src="assets/f3.svg" alt="" />
                </div>
                <h4 className="mb-3">Trade <br /> Splitting</h4>
                <p>Use our protocol to split your trade into smaller sizes.</p>
              </div>
            </div>

          </div>
        </div>
      </section>


      <section className="feature">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
          <h2 className="mb-0">Features</h2> 
          <div className="primary-text fs-20 text-md-start text-end"> + development zone</div>
          </div>
        </div>
        <div className="f1">
          <div className="row">
            <div className="col-md-6">
              <h3>AI Powered Dex aggregator</h3>
              <p className="mb-md-5 pb-4">Our algorithm’s structure can be upgradeable, reconfigured and also has self-improvement abilities.</p>

              <a href="javascript:void(0)" className="primary-btn">View Feature</a>
            </div>
          </div>
        </div>

        <div className="f2">
          <div className="row">
            <div className="col-md-3 col-8 mb-md-0 mb-4">
              <img src="assets/bg-2.png" className="w-100" alt="" />
            </div>
            <div className="col-md-6 offset-md-3">
              <h3><span className="primary-text">PlutoSwap Guard</span> Zero-Slippage</h3>
              <p className="mb-md-5 pb-4">You get the exact amount shown on the swap screen in your wallet. No slippage or tolerance.</p>

              <a href="javascript:void(0)" className="primary-btn">View Feature</a>
            </div>
          </div>
        </div>

        <div className="f3">
          <div className="row">
            <div className="col-md-6">
              <h3>Multichain (x,x) Compatible</h3>
              <p className="mb-md-5 pb-4">Multichain support and Chainfinder integration are built into the core code of the aggregator!</p>

              <a href="javascript:void(0)" className="primary-btn">View Feature</a>
            </div>
          </div>
        </div>

        <div className="f4">
          <div className="row">
          <div className="col-md-3 col-8 mb-md-0 mb-4">
              <img src="assets/bg-2.png" className="w-100" alt="" />
            </div>
            <div className="col-md-6 offset-md-3">
              <h3>Swap Fee Distribution</h3>
              <p className="mb-md-5 pb-4">PlutoSwap Treasury receives the protocol’s revenues and the AI-powered Vault Manager smartly distributes them.</p>

              <a href="javascript:void(0)" className="primary-btn">View Feature</a>
            </div>
          </div>
        </div>

        <div className="f5">
          <div className="row">
            <div className="col-md-6">
              <h3>Utility Token</h3>
              <p className="mb-md-5 pb-4">PlutoSwap’s governance token gives you voting rights and access to other exciting features of the protocol. Voting power and beyond!</p>

              <a href="javascript:void(0)" className="primary-btn">View Feature</a>
            </div>
          </div>
        </div>

        <div className="row mb-5 mt-5 pt-5">
          <div className="col-md-12">
          <div className="d-md-flex align-items-end gap-2"><h2 className="mb-0">DEX Aggregator </h2>
          <span className="fs-20 primary-text"> (Quoting part)</span></div>
          </div>
          <div className="col-md-12 mt-5">
            <img src="assets/dex.png" className="w-100" alt="" />
          </div>
        </div>


      </div>
      </section>

      {/* <section className="partner pb-5">
        <div className="container">
        <div className="grey-card">
        <div className="row">
        <div className="col-md-12 mb-4">
              <h2> <span className="">Partners & backers</span></h2>
            </div>
        </div>
        <div className="partner-grid pb-4">
        <div className="col-01">
            <div className="partner-img">
              <img src="assets/p1.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p2.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p3.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p4.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p5.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p6.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p7.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p8.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p9.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p10.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p11.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p12.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p13.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p14.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p15.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p16.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p17.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p18.svg"  alt="" />
            </div>
          </div>
          <div className="col-01">
            <div className="partner-img">
              <img src="assets/p19.svg"  alt="" />
            </div>
          </div>
        </div>
        </div>
        

 
        </div>
      </section> */}
    
     
    </>
  );
};

export default Home;
