import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container py-4">
          <div className="grey-card">
          <div className="row justify-content-between px-md-3 pt-md-4">
            <div className="col-md-3 col-6 mb-md-0 mb-4">
              <h5>Developers</h5>
              <ul>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Documentation</a></li>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">GitHub</a></li>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Audit</a></li>
              </ul>
            </div>

            <div className="col-md-3 col-6 mb-md-0 mb-4">
              <h5>Community</h5>
              <ul>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Discord</a></li>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Branding/Media Kit</a></li>
              </ul>
            </div>

            <div className="col-md-3 col-6 mb-md-0 mb-4">
              <h5>Application</h5>
              <ul>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Web App</a></li>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Submit Feedback</a></li>
              </ul>
            </div>

            <div className="col-md-3 col-6 mb-md-0 mb-4">
              <h5>Legal</h5>
              <ul>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Terms of Services</a></li>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              </ul>
            </div>
            
          </div>

          <div className="row justify-content-between align-items-center my-4 px-md-3">
            <div className="col-md-3 text-md-start text-center mb-md-0 mb-4">
            <Link to="/" className="navbar-brand">
            <img src="assets/logo.svg" alt="" />
          </Link>
            </div>
            <div className="col-md-9">
              <div className="d-flex flex-wrap gap-5 align-items-center justify-content-md-end justify-content-center mb-md-0 mb-3">
            <a href="javascript:void()" target={"_blank"} className="nav-ic">
            <div className="icon"><img src="assets/gt.svg" alt="" /></div>
                </a>
                <a href="javascript:void()" target={"_blank"} className="nav-ic">
                <div className="icon"><img src="assets/me.svg" alt="" /></div>
                </a>
                <a href="javascript:void()" target={"_blank"} className="nav-ic">
                <div className="icon"><img src="assets/tw.svg" alt="" /></div>
                </a>
                <a href="javascript:void()" target={"_blank"} className="nav-ic">
                <div className="icon"><img src="assets/dc.svg" alt="" /></div>
                </a>
                <a href="javascript:void()" target={"_blank"} className="nav-ic">
                <div className="icon"><img src="assets/zl.svg" alt="" /></div>
                </a>
                <a href="javascript:void()" target={"_blank"} className="primary-btn-outline">
                Docs
                </a>
            </div>
            </div>
          </div>

          <div className="row px-md-3">
            <div className="col-md-12">
            <ul className='d-flex justify-content-md-start justify-content-center gap-4 flex-wrap'>
                <li><p className='mb-0'>Copyright 2024 PlutonSwapX</p></li>
                <li><p className='mb-0'>All rights reserved</p></li>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Terms of Services</a></li>
                <li><a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer