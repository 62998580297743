import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Pages/Home";

function App() {
  return (
    <Router>
          <Header />        
            <Routes>
              <Route path="/" element={<Home />} />       
            </Routes>
          <Footer />         
    </Router>
  );
}

export default App;
